import React from 'react';
import aboutUsImg from "../../media/about-us-img.png";

const AboutUs = () => {
    return (
        <>
            <section id="about" className="section is-large unselectable section-block" unselectable="on" style={{ backgroundColor: "#F9F8FE", paddingBottom: "3vw", marginTop: "1vw", paddingTop: "1vw", justifyContent: "center", alignItems: "center" }}>
                <div style={{ paddingLeft: "1.5vw", paddingTop: "7vw", paddingRight: "6vw" }}>
                    <h1 className="title is-1 h1">About us</h1>
                    <div style={{ paddingTop: "1vw", fontWeight: 700, paddingBottom: "2vw" }}>
                        <span style={{ fontSize: "1.5vw", fontWeight: 500, lineHeight: "150%" }}>
                            We address users' daily challenges by consistently offering innovative solutions and introducing new products on the App Store. With a global focus, particularly in the US market, we possess a knack for reinventing product ideas and creating applications that truly connect with users.
                        </span>
                    </div>
                    <div className="columns" style={{ zIndex: "3", position: "relative" }}>
                        <div className="column">
                            <img unselectable="on" className="unselectable" src={aboutUsImg} alt="" style={{ maxWidth: "100%" }} />
                        </div>
                        <div className="column" style={{ display: "flex", flexDirection: "column", paddingLeft: "5vw" }}>
                            <div>
                                <p className="about-paragraph about-1 unselectable" unselectable="on">Our ultimate passion is fostering a global community where people worldwide discover joy in their daily habits.</p>
                                <p className="about-paragraph about-2 unselectable" unselectable="on">Come join us on this journey as we transform the ordinary into the extraordinary, one iOS application at a time.</p>
                            </div>
                            <div className="about-button-div">
                                <a style={{ width: "13vw", borderRadius: "3vw" }} className="button is-large join-us-btn is-responsive" href="https://www.linkedin.com/company/rooh-co">
                                    Join Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;

import React from "react";
import mobileTeam from "../../media/mobile-team.png";
import aboutUsImg from "../../media/about-us-img.png";
import globalMarket from "../../media/global_market.png";
import installs from "../../media/installs.png";
import products from "../../media/products.png";
import yearsOnMarket from "../../media/years_on_market.png";
import WhatUsersSay from "../MainPageComponents/WhatUsersSay";
import ourTeam from '../../media/our-team.png'
import schedule from '../../media/schedule.png';
import tasks from '../../media/tasks.png';
import balance from '../../media/balance.png';
import MobileVacancies from "./MobileVacancies";


const MobilePage = () => {
    const svgLogo = `<svg xmlns="http://www.w3.org/2000/svg" width="178" height="57" viewBox="0 0 178 57" fill="none">
    <path d="M16.1369 20.2421C16.8747 19.7952 17.5594 19.2663 18.1781 18.6655C18.8613 18.0008 19.453 17.2482 19.9376 16.4275C20.4079 15.6237 20.7668 14.7596 21.0043 13.8591C21.2434 12.9486 21.3639 12.011 21.3627 11.0696C21.3692 9.64421 21.08 8.23291 20.5134 6.92488C19.968 5.66084 19.1916 4.50959 18.224 3.53014C17.2513 2.55506 16.1019 1.77392 14.8371 1.2284C13.529 0.66189 12.1176 0.372762 10.692 0.37928H2.64768C2.28929 0.375759 1.93384 0.444179 1.60237 0.580487C1.28897 0.711949 1.00418 0.903204 0.763927 1.14357C0.520984 1.3871 0.329104 1.67667 0.199537 1.99532C0.0662334 2.32273 -0.00153738 2.67312 6.25762e-05 3.02663V31.9501C-0.00233809 32.2992 0.0643574 32.6454 0.196308 32.9686C0.328259 33.2918 0.522859 33.5858 0.768899 33.8335C1.01494 34.0812 1.30756 34.2778 1.62991 34.4119C1.95226 34.5461 2.29796 34.6152 2.64712 34.6152C2.99627 34.6152 3.34198 34.5461 3.66433 34.4119C3.98668 34.2778 4.2793 34.0812 4.52534 33.8335C4.77138 33.5858 4.96598 33.2918 5.09793 32.9686C5.22988 32.6454 5.29657 32.2992 5.29417 31.9501V21.7396H10.8238L18.0518 33.3073L18.06 33.3198C18.6301 34.1671 19.3905 34.5968 20.3198 34.5968C20.6733 34.5985 21.0236 34.5308 21.351 34.3974C21.6697 34.2678 21.9594 34.0759 22.203 33.8329C22.4433 33.5928 22.6346 33.3083 22.7662 32.9952C22.9025 32.6637 22.9708 32.3083 22.9673 31.95C22.9634 31.4401 22.8178 30.9413 22.5466 30.5095L16.1369 20.2421ZM16.0685 11.0691C16.072 11.7791 15.9283 12.4821 15.6465 13.1338C15.3701 13.7735 14.9797 14.3577 14.4942 14.8578C14.0083 15.354 13.4293 15.7494 12.7903 16.0215C12.1272 16.3046 11.4131 16.4484 10.6922 16.444H5.2948V5.67235H10.6922C11.4062 5.66954 12.1133 5.81341 12.7695 6.09503C14.0657 6.64353 15.0974 7.67514 15.646 8.97124C15.929 9.63425 16.0728 10.3483 16.0685 11.0691Z" fill="black"/>
    <path d="M43.9292 13.9268L43.9272 13.9248C41.5465 11.5733 38.6663 10.3809 35.3667 10.3809C32.0379 10.3809 29.157 11.574 26.8041 13.9268C24.4512 16.2795 23.258 19.1599 23.258 22.4883C23.258 25.8167 24.4511 28.6976 26.8041 31.05C29.1572 33.4024 32.0377 34.5957 35.3667 34.5957C38.6667 34.5957 41.5468 33.4034 43.9272 31.0519C46.2815 28.7265 47.4751 25.8453 47.4751 22.4845C47.4743 20.8941 47.1697 19.3184 46.5776 17.8423C45.9646 16.3737 45.0642 15.0424 43.9292 13.9268ZM29.0271 19.8272C29.7174 18.1781 31.0226 16.8624 32.6661 16.1588C33.5171 15.7981 34.4319 15.6122 35.3561 15.6122C36.2804 15.6122 37.1952 15.7981 38.0461 16.1588C38.8634 16.5124 39.6059 17.0183 40.2341 17.6495C40.857 18.2813 41.3564 19.024 41.7066 19.8392C42.061 20.6776 42.2436 21.5786 42.2436 22.4888C42.2436 23.399 42.061 24.3 41.7066 25.1384C41.3564 25.9536 40.8571 26.6963 40.2342 27.3282C39.6061 27.9593 38.8635 28.4651 38.0462 28.8187C37.1953 29.1795 36.2805 29.3654 35.3562 29.3654C34.4319 29.3654 33.5171 29.1795 32.6661 28.8187C31.0225 28.1151 29.7173 26.7994 29.0271 25.1502C28.6724 24.3078 28.4897 23.4031 28.4897 22.4891C28.4897 21.5752 28.6724 20.6704 29.0271 19.8281V19.8272Z" fill="black"/>
    <path d="M70.6054 13.9268L70.6034 13.9248C68.2229 11.5733 65.3426 10.3809 62.0429 10.3809C58.7141 10.3809 55.8334 11.574 53.4805 13.9268C51.1276 16.2795 49.9342 19.1599 49.9342 22.4883C49.9342 25.8167 51.1274 28.6976 53.4803 31.05C55.8332 33.4024 58.714 34.5957 62.0429 34.5957C65.343 34.5957 68.2231 33.4033 70.6034 31.0519C72.9579 28.7265 74.1516 25.8453 74.1516 22.4845C74.1507 20.8941 73.846 19.3184 73.2539 17.8423C72.641 16.3736 71.7405 15.0424 70.6054 13.9268ZM55.7035 19.8272C56.3938 18.1781 57.6989 16.8624 59.3425 16.1588C60.1934 15.7981 61.1082 15.6122 62.0324 15.6122C62.9567 15.6122 63.8715 15.7981 64.7224 16.1588C65.5395 16.5125 66.282 17.0182 66.9101 17.6492C67.5329 18.2811 68.0322 19.0238 68.3823 19.839C68.7368 20.6773 68.9194 21.5783 68.9194 22.4886C68.9194 23.3988 68.7368 24.2998 68.3823 25.1382C68.0322 25.9534 67.5329 26.696 66.9101 27.3279C66.282 27.9591 65.5393 28.465 64.7219 28.8186C63.871 29.1794 62.9562 29.3653 62.0319 29.3653C61.1077 29.3653 60.1929 29.1794 59.342 28.8186C58.5287 28.468 57.7897 27.9657 57.1645 27.3385C56.5441 26.7074 56.0483 25.9649 55.7031 25.15C55.3483 24.3077 55.1655 23.403 55.1655 22.489C55.1655 21.575 55.3483 20.6703 55.7031 19.8279L55.7035 19.8272Z" fill="black"/>
    <path d="M95.8395 13.7246C94.7969 12.7064 93.5759 11.8886 92.2374 11.3121C90.8702 10.7203 89.3954 10.4171 87.9055 10.4217C85.628 10.4217 83.5848 11.0048 81.8204 12.1567V2.99353C81.8179 2.6484 81.7473 2.30714 81.6127 1.98935C81.478 1.67156 81.2819 1.38348 81.0357 1.14163C80.67 0.776436 80.204 0.528223 79.6969 0.428555C79.1898 0.328887 78.6645 0.382267 78.1878 0.581905C77.7111 0.781544 77.3046 1.11842 77.0199 1.5497C76.7352 1.98098 76.5852 2.48718 76.589 3.00392V31.9687C76.589 32.6624 76.8646 33.3276 77.3551 33.8181C77.8457 34.3086 78.511 34.5842 79.2048 34.5842C79.8985 34.5842 80.5638 34.3086 81.0544 33.8181C81.5449 33.3276 81.8205 32.6624 81.8205 31.9687V21.7372C81.8169 20.93 81.9788 20.1307 82.2961 19.3885C82.9204 17.9237 84.0851 16.7557 85.5483 16.127C86.2932 15.8084 87.0959 15.6469 87.9062 15.6527C88.7132 15.6493 89.5121 15.8142 90.2518 16.1368C91.7132 16.7656 92.8781 17.9303 93.5071 19.3914C93.8297 20.1312 93.9945 20.9301 93.9912 21.7372V31.9687C93.9868 32.4857 94.1365 32.9923 94.4211 33.424C94.7057 33.8557 95.1124 34.1928 95.5894 34.3926C96.0663 34.5923 96.592 34.6455 97.0993 34.5455C97.6066 34.4455 98.0727 34.1967 98.4381 33.8309C98.6853 33.5876 98.8819 33.2978 99.0166 32.9782C99.1513 32.6586 99.2214 32.3155 99.2228 31.9687V21.7372C99.23 20.2173 98.9193 18.7128 98.3104 17.3201C97.7249 15.9743 96.886 14.7537 95.8395 13.7246Z" fill="black"/>
    <path d="M131.192 5.67212C132.522 5.66469 133.843 5.88184 135.101 6.31441C136.313 6.73792 137.447 7.35984 138.455 8.1548C138.827 8.50635 139.291 8.74314 139.794 8.83664C140.297 8.93015 140.816 8.87639 141.288 8.68184C141.607 8.55237 141.897 8.36045 142.14 8.11739C142.381 7.87717 142.572 7.59241 142.704 7.27902C142.84 6.94762 142.908 6.59225 142.905 6.23395C142.905 5.47467 142.663 4.85678 142.187 4.39744C142.179 4.38893 142.169 4.38104 142.16 4.37329C138.913 1.72219 135.223 0.37793 131.192 0.37793C126.494 0.37793 122.421 2.06854 119.087 5.40322C115.765 8.72388 114.081 12.7893 114.081 17.4864C114.081 22.1974 115.765 26.2704 119.087 29.5907C122.408 32.911 126.48 34.5951 131.192 34.5951C135.166 34.5951 138.785 33.3149 141.948 30.7903C141.962 30.7787 141.976 30.7661 141.989 30.7528C142.243 30.5076 142.446 30.2142 142.585 29.8898C142.724 29.5654 142.798 29.2164 142.8 28.8633C142.801 28.5134 142.731 28.1668 142.596 27.8443C142.46 27.5217 142.261 27.2296 142.01 26.9853C141.77 26.7544 141.491 26.567 141.186 26.4316C140.857 26.2867 140.502 26.2134 140.143 26.2166H140.141C139.596 26.2207 139.064 26.3855 138.612 26.6902C138.603 26.6966 138.594 26.7027 138.585 26.7103C136.466 28.4291 133.979 29.3004 131.192 29.3004C127.924 29.3004 125.104 28.1377 122.811 25.8445C120.532 23.565 119.376 20.7526 119.376 17.4853C119.376 14.2319 120.532 11.4265 122.811 9.14719C125.118 6.84195 127.938 5.67212 131.192 5.67212Z" fill="black"/>
    <path d="M165.638 13.9268L165.636 13.9248C163.255 11.5733 160.375 10.3809 157.075 10.3809C153.746 10.3809 150.866 11.5738 148.513 13.9268C146.16 16.2797 144.967 19.1599 144.967 22.4883C144.967 25.8167 146.16 28.6976 148.513 31.05C150.866 33.4024 153.746 34.5957 157.075 34.5957C160.375 34.5957 163.255 33.4034 165.636 31.0519C167.99 28.7265 169.184 25.8453 169.184 22.4845C169.183 20.8941 168.878 19.3185 168.286 17.8423C167.673 16.3737 166.773 15.0424 165.638 13.9268ZM150.736 19.8272C151.081 19.0125 151.577 18.2701 152.197 17.6389C152.823 17.0118 153.562 16.5095 154.375 16.1588C155.226 15.7981 156.141 15.6122 157.065 15.6122C157.989 15.6122 158.904 15.7981 159.755 16.1588C160.572 16.5124 161.315 17.0183 161.943 17.6493C162.566 18.2812 163.065 19.0239 163.415 19.8391C163.77 20.6775 163.952 21.5784 163.952 22.4887C163.952 23.3989 163.77 24.2999 163.415 25.1383C163.065 25.9535 162.566 26.6962 161.943 27.328C161.315 27.9593 160.572 28.4652 159.755 28.8187C158.904 29.1795 157.989 29.3654 157.065 29.3654C156.141 29.3654 155.226 29.1795 154.375 28.8187C153.562 28.4682 152.823 27.9659 152.197 27.3387C151.577 26.7075 151.081 25.965 150.736 25.1502C150.381 24.3078 150.198 23.4031 150.198 22.4891C150.198 21.5752 150.381 20.6704 150.736 19.8281V19.8272Z" fill="black"/>
    <path d="M177.006 28.7951C176.45 28.2368 175.717 27.8886 174.932 27.8101C174.148 27.7317 173.361 27.9276 172.705 28.3647C172.049 28.8018 171.564 29.4528 171.335 30.2069C171.105 30.9609 171.144 31.7713 171.445 32.4998C171.746 33.2284 172.291 33.8299 172.986 34.202C173.681 34.574 174.483 34.6935 175.256 34.54C176.03 34.3866 176.726 33.9697 177.226 33.3605C177.726 32.7512 178 31.9874 178 31.1991C178.006 30.7519 177.921 30.308 177.751 29.8947C177.58 29.4813 177.326 29.1071 177.006 28.795L177.006 28.7951Z" fill="black"/>
    <path d="M158.644 37.9838C164.053 38.5657 169.305 39.73 174.143 42.3321C174.831 42.706 175.462 43.1744 176.02 43.7236C176.735 44.4271 176.589 45.1846 175.628 45.4577C174.519 45.7864 173.371 45.9695 172.214 46.0022C161.87 46.147 151.522 46.1069 141.182 46.3837C131.017 46.6559 120.853 47.121 110.701 47.7055C96.7493 48.5084 82.8044 49.4199 68.8659 50.4401C54.1203 51.5236 39.3864 52.7685 24.6519 53.9977C17.3873 54.6037 10.1333 55.3396 2.87275 55.996C2.20639 56.0472 1.53642 56.0247 0.874992 55.9289C-0.0113856 55.8112 -0.255787 55.2232 0.286826 54.5063C0.631232 54.0362 1.06942 53.6427 1.57369 53.3506C2.07796 53.0585 2.63736 52.8741 3.21651 52.8092C9.06262 52.0509 14.9029 51.2474 20.751 50.5051C37.7195 48.3515 54.6826 46.1516 71.6646 44.1089C79.6221 43.1517 87.6142 42.471 95.5978 41.744C103.612 41.0142 111.63 40.3241 119.654 39.725C127.621 39.1301 135.595 38.6142 143.573 38.177C148.068 37.9364 152.576 37.9518 157.079 37.8665C157.6 37.8568 158.122 37.9424 158.644 37.9838Z" fill="#56C66E"/>
    </svg>`;

    return (
        <div>
            <div className="ellipse-mobile">
                <section
                    className="section is-large unselectable section-block"
                    unselectable="on"
                    style={{ paddingRight: "0", paddingTop: "20vw", paddingBottom: "3vw", position: "relative" }}
                >
                    <div className="columns intro-div" style={{ zIndex: 999, paddingRight: "6vw" }}>
                        <div className="column">
                            <img
                                unselectable="on"
                                src={mobileTeam}
                                className="unselectable"
                                alt=""
                                style={{ maxWidth: "100%" }}
                            />
                        </div>
                        <div
                            className="column"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                alignItems: "center",
                                // paddingLeft: "2.5vw",
                            }}
                        >
                            <img
                                src={`data:image/svg+xml;utf8,${encodeURIComponent(svgLogo)}`}
                                alt="logo"
                                style={{ width: "60%" }}
                                unselectable="on"
                                className="unselectable"
                            />
                            <div
                                className="intro-text-part"
                                style={{
                                    marginTop: "2.5vw",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignContent: "center",
                                    paddingBottom: "10vw",
                                }}
                            >
                                <p
                                    style={{ fontSize: "7vw", marginBottom: "1vw", fontWeight: 700 }}
                                    unselectable="on"
                                    className="unselectable"
                                >
                                    Reveal Your Uniqueness
                                </p>
                                <p
                                    style={{ fontSize: "5vw", marginTop: "1vw", fontWeight: 500 }}
                                    unselectable="on"
                                    className="unselectable"
                                >
                                    The Rooh Co. team excels in highly competitive and dynamic niches such as Lifestyle,
                                    Graphics & Design, and Utilities.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <section
                    id="about"
                    className="section is-large unselectable section-block"
                    unselectable="on"
                    style={{
                        paddingTop: "7vw",
                        backgroundColor: "#F9F8FE",
                        paddingBottom: "3vw",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div style={{ paddingLeft: "1.5vw", paddingTop: "2vw", paddingRight: "6vw" }}>
                        <h1 className="title is-1 h1" style={{ fontSize: "7vw" }}>
                            About us
                        </h1>
                        <div style={{ paddingTop: "1vw", fontWeight: 700, paddingBottom: "7vw" }}>
                            <span style={{ fontSize: "5vw", fontWeight: 500, lineHeight: "150%" }}>
                                We address users' daily challenges by consistently offering innovative solutions and
                                introducing new products on the App Store. With a global focus, particularly in the US
                                market, we possess a knack for reinventing product ideas and creating applications that
                                truly connect with users.
                            </span>
                        </div>
                        <div className="columns" style={{ zIndex: "3", position: "relative" }}>
                            <div className="column">
                                <img
                                    unselectable="on"
                                    className="unselectable"
                                    src={aboutUsImg}
                                    alt=""
                                    style={{ maxWidth: "100%" }}
                                />
                            </div>
                            <div
                                className="column"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: "5vw",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ fontSize: "5vw" }}>
                                    <p className="unselectable mb-5" unselectable="on">
                                        Our ultimate passion is fostering a global community where people worldwide
                                        discover joy in their daily habits.
                                    </p>
                                    <p className="unselectable" unselectable="on">
                                        Come join us on this journey as we transform the ordinary into the
                                        extraordinary, one iOS application at a time.
                                    </p>
                                </div>
                                <div className="about-button-div mt-4">
                                    <a
                                        style={{
                                            width: "50vw",
                                            borderRadius: "15vw",
                                            height: "13vw",
                                            fontSize: "5vw",
                                            fontWeight: 700,
                                        }}
                                        className="button is-large join-us-btn is-responsive"
                                        href="https://www.linkedin.com/company/rooh-co"
                                    >
                                        Join Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <section id="our-products" className="section is-large unselectable section-block" unselectable="on">
                    <div style={{ padding: "3vw" }}>
                        <h1 className="title is-1 h1" style={{ fontSize: "7vw" }}>
                            What makes us Special
                        </h1>
                        <div style={{ paddingTop: "3vw", fontWeight: 700 }}>
                            <span style={{ fontSize: "5vw", fontWeight: 500, lineHeight: "150%" }}>
                                We develop rapidly growing mobile applications by harnessing the synergistic impact of
                                our professional team and earning the genuine trust and reviews of our users. We
                                specialize in crafting innovative iOS applications that transform mundane routines into
                                delightful experiences.
                            </span>
                        </div>
                        <div className="columns is-multiline" style={{ marginTop: "3vw" }}>
                            <div className="column is-full-mobile is-half-tablet">
                                <div className="block center-content">
                                    <img src={installs} alt="" />
                                </div>
                                <div
                                    className="block about-text center-content"
                                    style={{ minHeight: "5vw", paddingTop: "1vw" }}
                                >
                                    <h1 className="title-mobile">
                                        10 Million
                                    </h1>
                                    <h1 className="title-mobile">
                                        Installs
                                    </h1>
                                </div>
                            </div>
                            <div className="column is-full-mobile is-half-tablet">
                                <div className="block center-content">
                                    <img src={products} alt="" />
                                </div>
                                <div
                                    className="block about-text center-content"
                                    style={{ minHeight: "5vw", paddingTop: "1vw" }}
                                >
                                    <h1 className="title-mobile">
                                        5 Top
                                    </h1>
                                    <h1 className="title-mobile">
                                        Products
                                    </h1>
                                </div>
                            </div>
                            <div className="column is-full-mobile is-half-tablet">
                                <div className="block center-content">
                                    <img src={yearsOnMarket} alt="" />
                                </div>
                                <div
                                    className="block about-text center-content"
                                    style={{ minHeight: "5vw", paddingTop: "1vw" }}
                                >
                                    <h1 className="title-mobile">
                                        5+ Years on 
                                    </h1>
                                    <h1 className="title-mobile">
                                        the Market
                                    </h1>
                                </div>
                            </div>
                            <div className="column is-full-mobile is-half-tablet">
                                <div className="block center-content">
                                    <img src={globalMarket} alt="" />
                                </div>
                                <div
                                    className="block about-text center-content"
                                    style={{ minHeight: "5vw", paddingTop: "1vw" }}
                                >
                                    <h1 className="title-mobile">
                                        Global
                                    </h1>
                                    <h1 className="title-mobile">
                                        Market
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <WhatUsersSay 
                    isMobile={true}
                />
            </div>
            <div>
                <section id="our-team" className="section is-large unselectable section-block">
                    <div style={{ paddingLeft: "1.5vw", paddingTop: "3vw", paddingRight: "6vw" }}>
                        <h1 className="title is-1"style={{ fontSize: "7vw" }}>Our Team</h1>
                        <div className="content" style={{ fontWeight: 700, paddingBottom: "3rem" }}>
                            <p style={{ fontSize: "5vw", fontWeight: 500, lineHeight: "150%" }}>
                                Our team of experts is committed to developing products that elevate the daily experiences of millions. We specifically partner with talented professionals, and we take pride in offering a collaborative environment that adds significant value for potential candidates.
                            </p>
                        </div>
                        <div className="columns">
                            <div className="column is-half">
                                <img className="image is-fullwidth" src={ourTeam} alt="Our Team" />
                            </div>
                            <div className="column is-half">
                                <div>
                                    <p style={{ marginTop: "1vw", fontSize: "5vw", fontWeight: 500, lineHeight: "150%" }}>Our team brings together a wealth of experience, including outstanding developers, talented designers, seasoned product and project managers, creative marketers, and user acquisition managers.</p>
                                    <p style={{ marginTop: "3vw", fontSize: "5vw", fontWeight: 500, lineHeight: "150%" }}>Each member of our team contributes a unique perspective, combining professionalism with a creative approach to create innovative products and ensure their successful implementation.</p>
                                    <p style={{ marginTop: "3vw", fontSize: "5vw", fontWeight: 500, lineHeight: "150%" }}>We take pride in our diverse team that is committed to continuous growth and improvement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <section id="what-we-offer" className="section is-large unselectable section-block" unselectable="on">
                    <div style={{ paddingLeft: "1.5vw", paddingTop: "3vw", paddingRight: "6vw" }}>
                        <h1 className="title is-1 h1" style={{ fontSize: "7vw" }}>What we offer</h1>
                        <div className="columns" style={{ paddingTop: "2vw" }}>
                            <div className="column">
                                <div className="card card-with-img" style={{ height: "100%" }}>
                                    <div className="card-image">
                                        <figure className="image is-4by3">
                                            <img src={schedule} alt="schedule" />
                                        </figure>
                                    </div>
                                    <div className="card-content">
                                        <div className="content">
                                            <p className="card-title">Convenient Schedule</p>
                                            <p>You can customize your work schedule and have the option to work remotely</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="card card-with-img" style={{ height: "100%" }}>
                                    <div className="card-image">
                                        <figure className="image is-4by3">
                                            <img src={tasks} alt="tasks" />
                                        </figure>
                                    </div>
                                    <div className="card-content">
                                        <div className="content">
                                            <p className="card-title">Interesting Tasks</p>
                                            <p>We value your time, so experienced managers ensure the quality of tasks</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="card card-with-img" style={{ height: "100%" }}>
                                    <div className="card-image">
                                        <figure className="image is-4by3">
                                            <img src={balance} alt="balance" />
                                        </figure>
                                    </div>
                                    <div className="card-content">
                                        <div className="content">
                                            <p className="card-title">Work-Life balance</p>
                                            <p>Paid vacation days, holidays, and sick leave contribute to work-life balance</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns" style={{paddingTop: "2vw"}}>
                            <div className="column"></div>
                            <div className="column" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <a style={{width: "50vw", height: "13vw", fontSize: "5vw", borderRadius: "30px"}} className="button is-large join-us-btn" href="https://www.linkedin.com/company/rooh-co">
                                    Join Us
                                </a>
                            </div>
                            <div className='column'></div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <section id="vacancies" className="section is-large unselectable section-block" unselectable="on">
                    <div style={{ paddingLeft: "1.5vw", paddingTop: "3vw", paddingRight: "6vw" }}>
                        <h1 className="title is-1 h1" style={{fontSize: "7vw"}}>Vacancies</h1>
                        <MobileVacancies />
                    </div>
                </section>
            </div>
            <div>
                <section style={{paddingTop: "10vw", paddingBottom: "10vw", backgroundColor: "#50BF67", justifyContent: "center", alignItems: "center", display: "flex", alignContent:"center", flexDirection: "column"}} className="section-block">
                    <div style={{width: "90%", backgroundColor: "#50BF67", justifyContent: "center", alignItems: "center", display: "flex", alignContent:"center", flexDirection: "column"}}> 
                        <span style={{fontSize: "7vw", fontWeight: 700, color: "white"}} >Become a part of our</span>
                        <span style={{fontSize: "7vw", fontWeight: 700, color: "white"}} >creative team!</span>
                        <a style={{width: "50vw", fontSize: "5vw", fontWeight: 700, borderRadius: "30px"}} className="button is-large join-us-btn-w mt-5 mb-4" href="https://www.linkedin.com/company/rooh-co">
                            Join Us
                        </a>
                    </div>
                </section>
            </div>
            <div>
                <div id="contacts" className="contacts-main section-block">
                    <div style={{width: "70%", justifyContent: "center", alignItems: "center", display: "flex", alignContent:"center", flexDirection: "column"}}>
                        <span style={{fontSize: "7vw", fontWeight: 700, color: "white"}}>Stay in Touch</span>
                        <div>
                            <a href="https://www.linkedin.com/company/rooh-co"><i className="fa-brands fa-linkedin"></i></a>
                            <a href="mailto: hr@rooh.co"><i className="fa-solid fa-envelope"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobilePage;

import React from 'react';

const BecomaAPartBlock = () => {
    return (
        <section className="section-block">
            <div style={{ paddingLeft: "5vw", paddingRight: "8vw", height: "10rem", backgroundColor: "#50BF67", justifyContent: "space-around", alignItems: "center", display: "flex"}}> 
                <span className="become-a-part-text">Become a part of our creative team!</span>
                <a style={{width: "13rem", borderRadius: "30px"}} className="button is-large join-us-btn-w" href="https://www.linkedin.com/company/rooh-co">
                    Join Us
                </a>
            </div>
        </section>
    );
};

export default BecomaAPartBlock;
import React from 'react';
import schedule from '../../media/schedule.png';
import tasks from '../../media/tasks.png';
import balance from '../../media/balance.png';

const WhatWeOffer = () => {
    return (
        <>
            <section id="what-we-offer" className="section is-large unselectable section-block" unselectable="on">
                <div style={{ paddingLeft: "1.5vw", paddingTop: "3vw", paddingRight: "6vw" }}>
                    <h1 className="title is-1 h1">What we offer</h1>
                    <div className="columns" style={{ paddingTop: "3rem" }}>
                        <div className="column">
                            <div className="card card-with-img" style={{ height: "100%" }}>
                                <div className="card-image">
                                    <figure className="image is-4by3">
                                        <img src={schedule} alt="schedule" />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <p className="card-title">Convenient Schedule</p>
                                        <p>You can customize your work schedule and have the option to work remotely</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="card card-with-img" style={{ height: "100%" }}>
                                <div className="card-image">
                                    <figure className="image is-4by3">
                                        <img src={tasks} alt="tasks" />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <p className="card-title">Interesting Tasks</p>
                                        <p>We value your time, so experienced managers ensure the quality of tasks</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="card card-with-img" style={{ height: "100%" }}>
                                <div className="card-image">
                                    <figure className="image is-4by3">
                                        <img src={balance} alt="balance" />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <p className="card-title">Work-Life balance</p>
                                        <p>Paid vacation days, holidays, and sick leave contribute to work-life balance</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns" style={{paddingTop: "3rem"}}>
                        <div className="column"></div>
                        <div className="column" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <a style={{width: "13rem", borderRadius: "30px"}} className="button is-large join-us-btn" href="https://www.linkedin.com/company/rooh-co">
                                Join Us
                            </a>
                        </div>
                        <div className='column'></div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WhatWeOffer;
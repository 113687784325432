import React from 'react';
import globalMarket from '../../media/global_market.png'
import installs from '../../media/installs.png'
import products from '../../media/products.png'
import yearsOnMarket from '../../media/years_on_market.png'

const OurProducts = () => {
    return (
        <>
            <section id="our-products" className="section is-large unselectable section-block" unselectable="on">
                <div style={{ paddingLeft: "1.5vw", paddingTop: "3vw", paddingRight: "6vw" }}>
                    <h1 className="title is-1 h1">What makes us Special</h1>
                    <div style={{ paddingTop: "1.5vw", fontWeight: 700, paddingBottom: "3vw" }}>
                        <span style={{ fontSize: "1.5vw", fontWeight: 500, lineHeight: "150%" }}>
                            We develop rapidly growing mobile applications by harnessing the synergistic impact of our professional team and earning the genuine trust and reviews of our users. We specialize in crafting innovative iOS applications that transform mundane routines into delightful experiences.
                        </span>
                    </div>
                    <div className="columns" style={{ marginTop: "3vw", maxHeight: "15vw" }}>
                        <div className="column">
                            <div className="block center-content">
                                <img src={installs} alt=""/>
                            </div>
                            <div className="block about-text center-content" style={{ minHeight: "5vw", paddingTop: "1vw" }}>
                                <h1 className="title" style={{ maxWidth: "70%" }}>10 Million Installs</h1>
                            </div>
                        </div>
                        <div className="column">
                            <div className="block center-content">
                                <img src={products} alt="" />
                            </div>
                            <div className="block about-text center-content" style={{ minHeight: "5vw", paddingTop: "1vw" }}>
                                <h1 className="title" style={{ maxWidth: "70%" }}>5 Top Products</h1>
                            </div>
                        </div>
                        <div className="column">
                            <div className="block center-content">
                                <img src={yearsOnMarket} alt=""/>
                            </div>
                            <div className="block about-text center-content" style={{ minHeight: "5vw", paddingTop: "1vw" }}>
                                <h1 className="title" style={{ maxWidth: "70%" }}>5+ Years on the Market</h1>
                            </div>
                        </div>
                        <div className="column">
                            <div className="block center-content">
                                <img src={globalMarket} alt="" />
                            </div>
                            <div className="block about-text center-content" style={{ minHeight: "5vw", paddingTop: "1vw" }}>
                                <h1 className="title" style={{ maxWidth: "70%" }}>Global Market</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurProducts;
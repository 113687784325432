import React from 'react'
import logo from "../../media/Rooh_Co_Transparent_Square_Black_croped.webp";
import friendlyTeam from "../../media/friendly_team.png";

const IntroBlock = () => {
    return (
        <>
            <section className="section is-large unselectable section-block" unselectable="on" style={{ paddingRight: "0", paddingTop: "8vw", paddingBottom: "3vw", position: "relative" }}>
                <div className="columns intro-div" style={{ zIndex: 999, paddingRight: "6vw" }}>
                    <div className="column" style={{ display: "flex", flexDirection: "column", paddingLeft: "2.5vw" }}>
                        <div id="image-block" unselectable="on" className="unselectable" style={{ marginTop: "1.5vw" }}>
                            <img src={logo} alt="logo" style={{ width: "75%" }} unselectable="on" className="unselectable" />
                        </div>
                        <div className="intro-text-part" style={{ marginTop: "2.5vw", maxWidth: "90%" }}>
                            <p style={{ fontSize: "2.5vw", marginBottom: "1vw", fontWeight: 700 }} unselectable="on" className="unselectable">Reveal Your Uniqueness</p>
                            <p style={{ fontSize: "1.5vw", marginTop: "1vw", fontWeight: 600 }} unselectable="on" className="unselectable">The Rooh Co. team excels in highly competitive and dynamic niches such as Lifestyle, Graphics & Design, and Utilities.</p>
                        </div>
                    </div>
                    <div className="column">
                        <img unselectable="on" className="unselectable" src={friendlyTeam} alt="" style={{ maxWidth: "100%" }} />
                    </div>
                </div>
                <div className="ellipse"></div>
            </section>
        </>
    );
};

export default IntroBlock;

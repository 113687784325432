import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import stars from "../../media/stars.png";

const WhatUsersSay = ({ isMobile = false }) => {
    const reviewsData = [
        {
            date: "12/09/2021",
            title: "Absolutely Wonderful!!!",
            content:
                "I’m an Apple “Fan-Girl” to begin with, so I’m always on the lookout for new, fun and easy to use apps for all of my devices...",
        },
        {
            date: "09/14/2023",
            title: "Daria",
            content:
                "One thing that continually impresses me about this app is how often new and fresh designs are added to the catalog!...",
        },
        {
            date: "09/22/2023",
            title: "FANTASTIC!",
            content:
                "This app is a must-have for anyone who wants to customize and personalize their iPhone! It comes packed with thousands of amazing...",
        },
        {
            date: "10/22/2023",
            title: "Love this app",
            content:
                "I enjoyed some of the features when I first purchased the app. Easy has a lot of different pictures widgets etc ...",
        },
    ];

    const settings = isMobile
        ? {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        }
        : {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
        };

    const fontSize = isMobile ? { fontSize: '4vw' } : {};

    return (
        <section id="what-users-say" className="section is-large unselectable section-block" unselectable="on">
            <div style={{ paddingLeft: "1.2vw", paddingTop: "3rem" }}>
                <div style={{ paddingBottom: "3rem" }}>
                    <h1 className="title is-1 h1" style={isMobile ? { fontSize: "7vw", marginLeft: "3vw" } : {}}>What Users say</h1>
                </div>
                <Slider {...settings}>
                    {reviewsData.map((review, index) => (
                        <div key={index} className="column">
                            <div className="card card-wus" style={{ height: "100%" }}>
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <img src={stars} style={{ maxWidth: "7.5rem" }} alt="" />
                                    </p>
                                    <p style={fontSize} className="card-header-title review-date">{review.date}</p>
                                </header>
                                <div className="card-content" style={{ height: "100%" }}>
                                    <div className="content" style={{ height: "100%" }}>
                                        <p className="card-title" style={{ fontSize: "1rem" }}>
                                            {review.title}
                                        </p>
                                        <p style={fontSize}>{review.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default WhatUsersSay;

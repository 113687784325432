import React from 'react';

const ContactsBlock = () => {
    return (
        <div id="contacts" className="contacts-main section-block">
            <span>Stay in Touch</span>
            <a href="https://www.linkedin.com/company/rooh-co"><i className="fa-brands fa-linkedin"></i></a>
            <a href="mailto: hr@rooh.co"><i className="fa-solid fa-envelope"></i></a>
        </div>
    );
};

export default ContactsBlock;
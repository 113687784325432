import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import motionDesigner from "../../media/motion_designer.png";
import productManager from "../../media/product_manager.png";
import uaManager from "../../media/ua_manager.png";
import iosDev from "../../media/ios_dev.png";
import iosDev2 from "../../media/ios_dev2.png";
import iosDev3 from "../../media/ios_dev3.png";
import iosDev4 from "../../media/ios_dev4.png";
import iosDev5 from "../../media/ios_dev5.png";

const MobileVacancies = () => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const renderVacancyCards = () => {
        const vacancyData = [
            {
                imgSrc: motionDesigner,
                title: "Motion Designer",
                details: [
                    "Experience from 2 years",
                    "Remote + Full-time",
                    "Commercial experience in creating Ads Creatives for TikTok and Meta",
                ],
            },
            {
                imgSrc: productManager,
                title: "Product Manager",
                details: [
                    "Experience in iOS Products from 1 year, Marketing Background",
                    "Remote + Full-time",
                    "Skills in UX-UI, experience of calculation of Unit-Economics",
                ],
            },
            {
                imgSrc: uaManager,
                title: "UA Manager",
                details: [
                    "Experience in UA (iOS apps) from 1 year",
                    "Remote + Full-time",
                    "Experience in acquisition iOS traffic TT, Meta, Google",
                ],
            },
            {
                imgSrc: iosDev,
                title: "iOS Developer",
                details: ["Experience from 1 year", "Swift", "Foundation, Realm/CoreData, GCD, UIKit, OperationQueue"],
            },
            {
                imgSrc: iosDev2,
                title: "iOS Developer",
                details: ["Experience from 1 year", "Swift", "Foundation, Realm/CoreData, GCD, UIKit, OperationQueue"],
            },
            {
                imgSrc: iosDev3,
                title: "iOS Developer",
                details: ["Experience from 1 year", "Swift", "Foundation, Realm/CoreData, GCD, UIKit, OperationQueue"],
            },
            {
                imgSrc: iosDev4,
                title: "iOS Developer",
                details: ["Experience from 1 year", "Swift", "Foundation, Realm/CoreData, GCD, UIKit, OperationQueue"],
            },
            {
                imgSrc: iosDev5,
                title: "iOS Developer",
                details: ["Experience from 1 year", "Swift", "Foundation, Realm/CoreData, GCD, UIKit, OperationQueue"],
            },
        ];

        return vacancyData.map((vacancy, index) => (
            <div key={index} className="column vacancies-column is-4">
                <div className="card card-with-img vacancies-card">
                    <div className="card-image">
                        <img className="vacancies-img" src={vacancy.imgSrc} alt={vacancy.title} />
                    </div>
                    <div className="card-content">
                        <div className="content">
                            <p className="card-title">{vacancy.title}</p>
                            <div>
                                <ul className="vacancies-ul">
                                    {vacancy.details.map((detail, i) => (
                                        <li key={i}>{detail}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div>
            <Slider {...sliderSettings}>{renderVacancyCards()}</Slider>
        </div>
    );
};

export default MobileVacancies;

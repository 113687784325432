import React, { useState, useEffect } from "react";

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleNav = () => {
        setIsActive(!isActive);
    };
    const isMobile = window.innerWidth <= 768;

    const svgLogo = `<svg xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9996 15.1454C12.3131 14.9555 12.6041 14.7307 12.8671 14.4753C13.1574 14.1928 13.4089 13.873 13.6148 13.5242C13.8147 13.1825 13.9672 12.8153 14.0682 12.4326C14.1698 12.0456 14.221 11.6472 14.2205 11.2471C14.2233 10.6413 14.1004 10.0415 13.8595 9.48555C13.6277 8.94833 13.2978 8.45905 12.8866 8.04279C12.4731 7.62837 11.9846 7.29639 11.4471 7.06455C10.8912 6.82378 10.2913 6.7009 9.68547 6.70367H6.26662C6.11431 6.70217 5.96324 6.73125 5.82237 6.78918C5.68917 6.84505 5.56813 6.92634 5.46603 7.02849C5.36278 7.13199 5.28123 7.25506 5.22616 7.39048C5.16951 7.52964 5.1407 7.67855 5.14138 7.82879V20.1213C5.14036 20.2696 5.16871 20.4168 5.22479 20.5541C5.28087 20.6915 5.36357 20.8164 5.46814 20.9217C5.57271 21.027 5.69707 21.1105 5.83407 21.1675C5.97107 21.2246 6.11799 21.2539 6.26638 21.2539C6.41477 21.2539 6.5617 21.2246 6.6987 21.1675C6.8357 21.1105 6.96006 21.027 7.06462 20.9217C7.16919 20.8164 7.2519 20.6915 7.30798 20.5541C7.36406 20.4168 7.3924 20.2696 7.39138 20.1213V15.7818H9.74147L12.8134 20.6981L12.8168 20.7034C13.0592 21.0635 13.3823 21.2461 13.7773 21.2461C13.9275 21.2469 14.0764 21.2181 14.2155 21.1614C14.351 21.1063 14.4741 21.0247 14.5776 20.9215C14.6798 20.8194 14.7611 20.6985 14.817 20.5654C14.8749 20.4246 14.904 20.2735 14.9025 20.1212C14.9008 19.9045 14.8389 19.6925 14.7236 19.509L11.9996 15.1454ZM11.9705 11.2469C11.9719 11.5486 11.9109 11.8474 11.7911 12.1243C11.6737 12.3962 11.5077 12.6445 11.3014 12.857C11.0949 13.0679 10.8488 13.236 10.5772 13.3516C10.2954 13.4719 9.99192 13.533 9.68552 13.5312H7.39165V8.95322H9.68552C9.98901 8.95203 10.2895 9.01317 10.5684 9.13286C11.1193 9.36597 11.5578 9.80441 11.7909 10.3553C11.9112 10.637 11.9723 10.9405 11.9705 11.2469Z" fill="black"/>
    <path d="M23.8114 12.4612L23.8106 12.4604C22.7988 11.461 21.5746 10.9542 20.1723 10.9542C18.7576 10.9542 17.5332 11.4613 16.5332 12.4612C15.5332 13.4612 15.0261 14.6853 15.0261 16.0999C15.0261 17.5145 15.5332 18.7388 16.5332 19.7386C17.5333 20.7384 18.7575 21.2456 20.1723 21.2456C21.5748 21.2456 22.7989 20.7388 23.8106 19.7394C24.8111 18.7511 25.3184 17.5266 25.3184 16.0983C25.3181 15.4223 25.1886 14.7527 24.937 14.1253C24.6764 13.5012 24.2938 12.9354 23.8114 12.4612ZM17.478 14.9689C17.7714 14.2681 18.3261 13.7089 19.0246 13.4098C19.3862 13.2565 19.775 13.1775 20.1678 13.1775C20.5606 13.1775 20.9494 13.2565 21.3111 13.4098C21.6584 13.5601 21.974 13.7751 22.241 14.0434C22.5057 14.3119 22.718 14.6275 22.8668 14.974C23.0174 15.3303 23.095 15.7133 23.095 16.1001C23.095 16.4869 23.0174 16.8699 22.8668 17.2262C22.718 17.5726 22.5057 17.8883 22.241 18.1568C21.974 18.4251 21.6585 18.64 21.3111 18.7903C20.9495 18.9436 20.5607 19.0227 20.1678 19.0227C19.775 19.0227 19.3862 18.9436 19.0246 18.7903C18.326 18.4913 17.7713 17.9321 17.478 17.2312C17.3273 16.8732 17.2496 16.4887 17.2496 16.1002C17.2496 15.7118 17.3273 15.3273 17.478 14.9693V14.9689Z" fill="black"/>
    <path d="M35.1487 12.4612L35.1478 12.4604C34.1361 11.461 32.912 10.9542 31.5096 10.9542C30.0949 10.9542 28.8706 11.4613 27.8706 12.4612C26.8706 13.4612 26.3634 14.6853 26.3634 16.0999C26.3634 17.5145 26.8705 18.7388 27.8705 19.7386C28.8705 20.7384 30.0948 21.2456 31.5096 21.2456C32.9121 21.2456 34.1362 20.7387 35.1478 19.7394C36.1485 18.7511 36.6558 17.5266 36.6558 16.0983C36.6554 15.4223 36.5259 14.7527 36.2743 14.1253C36.0138 13.5011 35.6311 12.9354 35.1487 12.4612ZM28.8153 14.9689C29.1087 14.2681 29.6634 13.7089 30.3619 13.4098C30.7236 13.2565 31.1124 13.1775 31.5052 13.1775C31.898 13.1775 32.2868 13.2565 32.6484 13.4098C32.9957 13.5602 33.3112 13.7751 33.5782 14.0433C33.8429 14.3118 34.0551 14.6275 34.2038 14.9739C34.3545 15.3302 34.4321 15.7131 34.4321 16.1C34.4321 16.4868 34.3545 16.8698 34.2038 17.2261C34.0551 17.5725 33.8429 17.8882 33.5782 18.1567C33.3112 18.425 32.9956 18.64 32.6482 18.7902C32.2865 18.9436 31.8978 19.0226 31.5049 19.0226C31.1121 19.0226 30.7233 18.9436 30.3617 18.7902C30.0161 18.6413 29.702 18.4278 29.4363 18.1612C29.1726 17.893 28.9619 17.5774 28.8152 17.2311C28.6644 16.8731 28.5867 16.4886 28.5867 16.1002C28.5867 15.7117 28.6644 15.3272 28.8152 14.9692L28.8153 14.9689Z" fill="black"/>
    <path d="M45.8731 12.3756C45.43 11.9428 44.9111 11.5953 44.3423 11.3503C43.7612 11.0987 43.1344 10.9699 42.5012 10.9718C41.5332 10.9718 40.6649 11.2197 39.915 11.7092V7.81488C39.914 7.6682 39.884 7.52317 39.8267 7.38811C39.7695 7.25305 39.6862 7.13061 39.5815 7.02783C39.4261 6.87262 39.2281 6.76713 39.0125 6.72477C38.797 6.68241 38.5738 6.7051 38.3712 6.78994C38.1686 6.87479 37.9958 7.01796 37.8748 7.20126C37.7538 7.38455 37.6901 7.59968 37.6917 7.8193V20.1293C37.6917 20.4241 37.8088 20.7069 38.0173 20.9153C38.2258 21.1238 38.5085 21.2409 38.8034 21.2409C39.0982 21.2409 39.381 21.1238 39.5895 20.9153C39.798 20.7069 39.9151 20.4241 39.9151 20.1293V15.7809C39.9136 15.4379 39.9823 15.0982 40.1172 14.7827C40.3825 14.1602 40.8775 13.6638 41.4994 13.3966C41.816 13.2612 42.1571 13.1926 42.5015 13.195C42.8445 13.1936 43.184 13.2637 43.4984 13.4008C44.1195 13.668 44.6145 14.163 44.8819 14.784C45.019 15.0984 45.089 15.4379 45.0876 15.7809V20.1293C45.0857 20.3491 45.1494 20.5644 45.2703 20.7478C45.3913 20.9313 45.5641 21.0746 45.7668 21.1595C45.9695 21.2444 46.1929 21.267 46.4085 21.2245C46.6242 21.182 46.8222 21.0762 46.9775 20.9207C47.0826 20.8174 47.1662 20.6942 47.2234 20.5584C47.2807 20.4225 47.3105 20.2767 47.3111 20.1293V15.7809C47.3141 15.135 47.182 14.4956 46.9233 13.9037C46.6744 13.3317 46.3179 12.8129 45.8731 12.3756Z" fill="black"/>
    <path d="M60.8977 8.95316C61.4629 8.95 62.0245 9.04229 62.559 9.22613C63.0743 9.40612 63.5561 9.67044 63.9848 10.0083C64.1425 10.1577 64.3401 10.2583 64.5537 10.2981C64.7673 10.3378 64.9878 10.315 65.1887 10.2323C65.3242 10.1773 65.4474 10.0957 65.5509 9.99239C65.6531 9.8903 65.7344 9.76928 65.7902 9.63609C65.8481 9.49524 65.8772 9.34421 65.8757 9.19193C65.8757 8.86924 65.7731 8.60664 65.5708 8.41142C65.5672 8.4078 65.5632 8.40445 65.5593 8.40115C64.1791 7.27443 62.6108 6.70312 60.8978 6.70312C58.9013 6.70312 57.1703 7.42164 55.753 8.83887C54.3415 10.2502 53.6256 11.978 53.6256 13.9742C53.6256 15.9764 54.3413 17.7074 55.753 19.1186C57.1648 20.5297 58.8954 21.2454 60.8978 21.2454C62.5867 21.2454 64.1247 20.7013 65.4691 19.6284C65.4752 19.6234 65.481 19.6181 65.4865 19.6124C65.5945 19.5082 65.6806 19.3836 65.7399 19.2457C65.7991 19.1078 65.8302 18.9595 65.8314 18.8094C65.8316 18.6607 65.802 18.5134 65.7443 18.3763C65.6866 18.2392 65.602 18.1151 65.4955 18.0112C65.3933 17.9131 65.2748 17.8335 65.1453 17.7759C65.0056 17.7144 64.8544 17.6832 64.7018 17.6846H64.7011C64.4695 17.6863 64.2434 17.7563 64.0513 17.8859C64.0474 17.8886 64.0436 17.8912 64.04 17.8944C63.1395 18.6249 62.0823 18.9952 60.8978 18.9952C59.509 18.9952 58.3106 18.501 57.336 17.5264C56.3671 16.5576 55.8759 15.3624 55.8759 13.9737C55.8759 12.5911 56.3671 11.3988 57.336 10.4301C58.3164 9.45033 59.5147 8.95316 60.8977 8.95316Z" fill="black"/>
    <path d="M75.5374 12.4612L75.5366 12.4604C74.5248 11.461 73.3007 10.9542 71.8983 10.9542C70.4836 10.9542 69.2593 11.4612 68.2593 12.4612C67.2593 13.4612 66.7522 14.6853 66.7522 16.0999C66.7522 17.5145 67.2593 18.7388 68.2593 19.7386C69.2593 20.7384 70.4836 21.2456 71.8983 21.2456C73.3008 21.2456 74.5249 20.7388 75.5366 19.7394C76.5371 18.7511 77.0445 17.5266 77.0445 16.0983C77.0441 15.4224 76.9147 14.7527 76.6631 14.1253C76.4025 13.5012 76.0198 12.9354 75.5374 12.4612ZM69.204 14.9689C69.3508 14.6227 69.5616 14.3071 69.8252 14.0389C70.091 13.7724 70.405 13.5589 70.7507 13.4098C71.1123 13.2565 71.5011 13.1775 71.8939 13.1775C72.2867 13.1775 72.6755 13.2565 73.0371 13.4098C73.3845 13.5601 73.7 13.7751 73.967 14.0433C74.2317 14.3119 74.444 14.6275 74.5928 14.974C74.7434 15.3303 74.8211 15.7132 74.8211 16.1C74.8211 16.4869 74.7434 16.8698 74.5928 17.2261C74.444 17.5726 74.2318 17.8882 73.9671 18.1568C73.7001 18.425 73.3845 18.6401 73.0371 18.7903C72.6755 18.9436 72.2867 19.0227 71.8939 19.0227C71.5011 19.0227 71.1123 18.9436 70.7507 18.7903C70.405 18.6413 70.0909 18.4279 69.8252 18.1613C69.5616 17.893 69.3508 17.5775 69.204 17.2312C69.0533 16.8732 68.9757 16.4887 68.9757 16.1002C68.9757 15.7118 69.0533 15.3273 69.204 14.9693V14.9689Z" fill="black"/>
    <path d="M80.369 18.7803C80.1325 18.543 79.821 18.395 79.4876 18.3617C79.1542 18.3283 78.8196 18.4116 78.5408 18.5974C78.2619 18.7831 78.0562 19.0598 77.9586 19.3803C77.8609 19.7008 77.8775 20.0452 78.0055 20.3548C78.1334 20.6644 78.3648 20.9201 78.6602 21.0782C78.9556 21.2363 79.2966 21.2871 79.6253 21.2219C79.9539 21.1567 80.2498 20.9795 80.4624 20.7206C80.675 20.4616 80.7912 20.137 80.7912 19.802C80.7939 19.6119 80.7579 19.4233 80.6853 19.2476C80.6127 19.0719 80.505 18.9129 80.3689 18.7803L80.369 18.7803Z" fill="black"/>
    <path d="M72.5651 22.6861C74.8638 22.9334 77.0958 23.4282 79.1522 24.5341C79.4444 24.693 79.7128 24.8921 79.9496 25.1255C80.2538 25.4244 80.1918 25.7464 79.7833 25.8624C79.3117 26.0022 78.824 26.08 78.3323 26.0939C73.9362 26.1554 69.5383 26.1384 65.1439 26.256C60.8236 26.3717 56.504 26.5694 52.1891 26.8178C46.2598 27.159 40.3332 27.5464 34.4093 27.98C28.1425 28.4405 21.8806 28.9696 15.6184 29.4919C12.531 29.7495 9.44802 30.0622 6.36228 30.3412C6.07907 30.363 5.79434 30.3534 5.51323 30.3127C5.13652 30.2627 5.03265 30.0128 5.26326 29.7081C5.40963 29.5083 5.59586 29.3411 5.81018 29.2169C6.02449 29.0928 6.26223 29.0144 6.50838 28.9868C8.99297 28.6646 11.4751 28.3231 13.9605 28.0076C21.1722 27.0923 28.3815 26.1574 35.5988 25.2892C38.9808 24.8824 42.3774 24.5931 45.7704 24.2841C49.1763 23.974 52.584 23.6807 55.9944 23.4261C59.3805 23.1732 62.769 22.9539 66.16 22.7682C68.0702 22.6659 69.9863 22.6725 71.8998 22.6362C72.1213 22.6321 72.3434 22.6685 72.5651 22.6861Z" fill="#56C66E"/>
    </svg>`;

    const [activeSection, setActiveSection] = useState("");

    const handleNavClick = (sectionId) => {
        setActiveSection(sectionId);
        const targetSection = document.getElementById(sectionId);

        if (targetSection) {
            window.scrollTo({
                top: targetSection.offsetTop - 20, // Adjust as needed
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section"); // assuming your sections have the 'section' tag
            const scrollPosition = window.scrollY + window.innerHeight / 2;

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionBottom = sectionTop + section.offsetHeight;

                if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const getButtonColor = (sectionId) => {
        return sectionId === activeSection ? "#000" : "#A6A6A6";
    };

    return (
        <div>
            <nav
                id="main-navbar"
                className="navbar is-fixed-top is-transparent"
                role="navigation"
                aria-label="main navigation"
                style={{
                    paddingLeft: "5vw",
                    paddingRight: "7vw",
                    borderBottomLeftRadius: "3vw",
                    borderBottomRightRadius: "3vw",
                    minHeight: "6vw",
                }}
            >
                <div className="navbar-brand navbar-end">
                    <a unselectable="on" className="unselectable navbar-item" href="https://rooh.co">
                        <img
                            unselectable="on"
                            className="unselectable nav-logo"
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(svgLogo)}`}
                            alt=""
                            width={isMobile ? "100" : "86"}
                            height={isMobile ? "150" : "34"}
                        />
                    </a>
                    <a
                        role="button"
                        className={`navbar-burger burger burger-btn ${isActive ? "is-active" : ""}`}
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbar-end"
                        onClick={toggleNav}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div
                    id="navbar-end"
                    className={`navbar-menu is-fullwidth ${isActive ? "is-active" : ""}`}
                    style={{ margin: "0 2vw" }}
                >
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <a className="is-white navbtns" style={{ color: getButtonColor("about") }} onClick={() => handleNavClick('about')} href="#about">
                                About Us
                            </a>
                        </div>
                        <div className="navbar-item">
                            <a
                                className="is-white navbtns"
                                style={{ color: getButtonColor("our-products") }}
                                href="#our-products"
                                onClick={() => handleNavClick('our-products')}
                            >
                                Our Products
                            </a>
                        </div>
                        <div className="navbar-item">
                            <a
                                className="is-white navbtns"
                                style={{ color: getButtonColor("vacancies") }}
                                href="#vacancies"
                                onClick={() => handleNavClick('vacancies')}
                            >
                                Vacancies
                            </a>
                        </div>
                        <div className="navbar-item">
                            <a href="#contacts" className="button is-success rooh-button contact-us">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;

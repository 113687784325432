import React from 'react';
import ScrollContainer from '../utils/ScrollContainer';

const Vacancies = () => {
    return (
        <>
            <section id="vacancies" className="section is-large unselectable section-block" unselectable="on">
                <div style={{ paddingLeft: "1.5vw", paddingTop: "3vw", paddingRight: "6vw" }}>
                    <h1 className="title is-1 h1">Vacancies</h1>
                    <ScrollContainer />
                </div>
            </section>
        </>
    );
};

export default Vacancies;
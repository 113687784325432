import { Routes, Route } from "react-router-dom";
import MainPage from "./components/MainPage";
import VacanciesPage from "./components/VacanciesPage";


function App() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      {/* <Route path="/vacancies" element={<VacanciesPage />} /> */}
    </Routes>
  );
}

export default App;

import React, { useRef, useState } from "react";
import motionDesigner from "../../media/motion_designer.png";
import productManager from "../../media/product_manager.png";
import uaManager from "../../media/ua_manager.png";
import iosDev from "../../media/ios_dev.png";
import iosDev2 from "../../media/ios_dev2.png";
import iosDev3 from "../../media/ios_dev3.png";
import iosDev4 from "../../media/ios_dev4.png";
import iosDev5 from "../../media/ios_dev5.png";


const Draggable = ({ innerRef, rootClass = "", children }) => {
    const ourRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0,
    });
    const [isScrolling, setIsScrolling] = useState(false);
    const handleDragStart = (e) => {
        if (!ourRef.current) return;
        const slider = ourRef.current.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
        setIsMouseDown(true);
        document.body.style.cursor = "grabbing";
    };
    const handleDragEnd = () => {
        setIsMouseDown(false);
        if (!ourRef.current) return;
        document.body.style.cursor = "default";
    };
    const handleDrag = (e) => {
        if (!isMouseDown || !ourRef.current) return;
        e.preventDefault();
        const slider = ourRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
        console.log(walkX, walkY);
    };

    return (
        <div
            ref={ourRef}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDrag}
            className={rootClass + "flex overflow-x-scroll scroll-container"}
        >
            {children}
        </div>
    );
};

const ScrollContainer = () => {
    const scrollContainerRef = useRef(null);
    return (
        <Draggable innerRef={scrollContainerRef} rootClass={"drag"}>
            <div className="columns" style={{ paddingTop: "3rem", overflowX: "auto" }} ref={scrollContainerRef}>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={motionDesigner} alt="motion designer" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">Motion Designer</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience from 2 year</li>
                                        <li>Remote + Full-time</li>
                                        <li>Commercial experience in creating Ads Creatives for TikTok and Meta</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={productManager} alt="product manager" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">Product Manager</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience in iOS Products from 1 year, Marketing Background</li>
                                        <li>Remote + Full-time</li>
                                        <li>Skills in UX-UI, experience of calculation of Unit-Economics</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={uaManager} alt="ua manager" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">UA Manager</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience in UA (iOS apps) from 1 year</li>
                                        <li>Remote + Full-time</li>
                                        <li>Experience in acquisition iOS traffic TT, Meta, Google</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={iosDev} alt="iosdev" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">iOS Developer</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience from 1 year</li>
                                        <li>Swift</li>
                                        <li>Foundation, Realm/CoreData, GCD, UIKit, OperationQueue</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={iosDev2} alt="iosdev" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">iOS Developer</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience from 1 year</li>
                                        <li>Swift</li>
                                        <li>Foundation, Realm/CoreData, GCD, UIKit, OperationQueue</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={iosDev3} alt="iosdev" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">iOS Developer</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience from 1 year</li>
                                        <li>Swift</li>
                                        <li>Foundation, Realm/CoreData, GCD, UIKit, OperationQueue</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={iosDev4} alt="iosdev" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">iOS Developer</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience from 1 year</li>
                                        <li>Swift</li>
                                        <li>Foundation, Realm/CoreData, GCD, UIKit, OperationQueue</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column vacancies-column is-4">
                    <div className="card card-with-img vacancies-card">
                        <div className="card-image">
                            <img className="vacancies-img" src={iosDev5} alt="iosdev" />
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <p className="card-title">iOS Developer</p>
                                <div>
                                    <ul className="vacancies-ul">
                                        <li>Experience from 1 year</li>
                                        <li>Swift</li>
                                        <li>Foundation, Realm/CoreData, GCD, UIKit, OperationQueue</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default ScrollContainer;

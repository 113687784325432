import React, { useState, useEffect } from "react";
import Navbar from "./MainPageComponents/NavBar";
import IntroBlock from "./MainPageComponents/IntroBlock";
import AboutUs from "./MainPageComponents/AboutUs";
import OurProducts from "./MainPageComponents/OurProducts";
import WhatUsersSay from "./MainPageComponents/WhatUsersSay";
import OurTeam from "./MainPageComponents/OurTeam";
import WhatWeOffer from "./MainPageComponents/WhatWeOffer";
import Vacancies from "./MainPageComponents/Vacancies";
import BecomaAPartBlock from "./MainPageComponents/BecomeAPArtBlock";
import ContactsBlock from "./MainPageComponents/ContactsBlock";
import MobilePage from "./MobileComponents/MobilePage";

const MainPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            <Navbar />
            {isMobile ? (
                <MobilePage />
            ) : (
                <>
                <IntroBlock />
                <AboutUs />
                <OurProducts />
                <WhatUsersSay />
                <OurTeam />
                <WhatWeOffer />
                <Vacancies />
                <div className="block-contacts">
                    <BecomaAPartBlock />
                    <ContactsBlock />
                </div>
                </>
            )}
            
        </div>
    );
};

export default MainPage;

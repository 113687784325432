import React from 'react';
import ourTeam from '../../media/our-team.png'

const OurTeam = () => {
    return (
        <>
            <section id="our-team" className="section is-large unselectable section-block">
                <div style={{ paddingLeft: "1.5vw", paddingTop: "3vw", paddingRight: "6vw" }}>
                    <h1 className="title is-1">Our Team</h1>
                    <div className="content" style={{ fontWeight: 700, paddingBottom: "3rem" }}>
                        <p style={{ fontSize: "1.5rem", fontWeight: 500, lineHeight: "150%" }}>
                            Our team of experts is committed to developing products that elevate the daily experiences of millions. We specifically partner with talented professionals, and we take pride in offering a collaborative environment that adds significant value for potential candidates.
                        </p>
                    </div>
                    <div className="columns">
                        <div className="column is-half">
                            <img className="image is-fullwidth" src={ourTeam} alt="Our Team" />
                        </div>
                        <div className="column is-half" style={{ paddingLeft: "2rem" }}>
                            <div>
                                <p style={{ marginTop: "1vw", fontSize: "1.3vw", fontWeight: 500, lineHeight: "150%" }}>Our team brings together a wealth of experience, including outstanding developers, talented designers, seasoned product and project managers, creative marketers, and user acquisition managers.</p>
                                <p style={{ marginTop: "2vw", fontSize: "1.3vw", fontWeight: 500, lineHeight: "150%" }}>Each member of our team contributes a unique perspective, combining professionalism with a creative approach to create innovative products and ensure their successful implementation.</p>
                                <p style={{ marginTop: "2vw", fontSize: "1.3vw", fontWeight: 500, lineHeight: "150%" }}>We take pride in our diverse team that is committed to continuous growth and improvement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurTeam;